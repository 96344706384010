.app-fake-switch {
    position: relative;
    background-color: rgba(var(--app-gray_200), 1);
    border-radius: 1rem;
    width: 2rem;
    height: 1.1rem;
}

.app-fake-switch.active {
    background-color: rgba(var(--app-primary_500), 1);
}

.app-fake-switch::before {
    content: "";
    position: absolute;
    left: 0.1rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(var(--app-gray_0), 1);
    border-radius: 0.9rem;
    width: 0.9rem;
    height: 0.9rem;
}

.app-fake-switch.active::before {
    content: "";
    left: calc(100% - 1rem);
}
.flexgrid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-1 * var(--flexgrid-gap) / 2);
}

.flexgrid--no-grow>* {
    flex-grow: 0 !important;
}

.flexgrid>* {
    flex: 1 1 clamp(var(--flexgrid-col-min-width), 100%, 100%);
    padding: calc(var(--flexgrid-gap) / 2);
}

.flexgrid--2>* {
    flex: 1 1 clamp(var(--flexgrid-col-min-width), 50%, 50%);
    padding: calc(var(--flexgrid-gap) / 2);
}

.flexgrid--3>* {
    flex: 1 1 clamp(var(--flexgrid-col-min-width), 33.33%, 33.33%);
    padding: calc(var(--flexgrid-gap) / 2);
}

.flexgrid--4>* {
    flex: 1 1 clamp(var(--flexgrid-col-min-width), 25%, 25%);
    padding: calc(var(--flexgrid-gap) / 2);
}

.flexgrid--5>* {
    flex: 1 1 clamp(var(--flexgrid-col-min-width), 20%, 20%);
    padding: calc(var(--flexgrid-gap) / 2);
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --app-body-background: var(--app-gray_100);
  --app-sidebar-gradient: linear-gradient(135deg, rgba(var(--app-blue_400), 0.9), rgba(var(--app-indigo_400), 0.6));
  --flexgrid-col-min-width: 20ch;
  --flexgrid-gap: 1rem;
  --custom-nav-background: var(--app-primary_500);
  --custom-nav-color: var(--app-gray_100);
  --custom-nav__item-background: var(--app-gray_100);
  --custom-nav__item-color: var(--app-primary_500);
  --custom-nav__link-background: var(--app-gray_100);
  --custom-nav__link-color: var(--app-gray_0);
}
th[sortable] {
    cursor: pointer;
    user-select: none;
}

th[sortable]::before {
    content: '\F3CA';
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-right: 4px;
    vertical-align: -0.125em;
}

th[sortable].desc::before,
th[sortable].asc::before {
    content: '\F57B';
}

th[sortable].desc::before {
    content: '\F575';
}
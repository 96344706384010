body {
    background: rgba(var(--app-body-background), 1);
    font-family: "Roboto", var(--bs-font-sans-serif);
}

ngb-typeahead-window {
    max-height: 300px;
    overflow: auto;
}

ngb-typeahead-window .dropdown-item {
    white-space: initial;
}

.pagination {
    margin-bottom: 0 !important;
}
.app-round-btn {
    box-shadow: 0 4px 8px 0 #0005;
    border-radius: 50%;
    color: #777;
    display: grid;
    line-height: 0;
    height: 42px;
    place-items: center;
    width: 42px;
    transition: box-shadow 300ms ease;
}

.app-round-btn:hover {
    box-shadow: 0 10px 16px 0 #0004;
}
.custom-nav,
.custom-nav__item,
.custom-nav__item>.nav-link {
    all: unset;
}

.custom-nav {
    background: rgba(var(--custom-nav-background), 1);
    box-shadow: 0 -12px 16px 0 #0002;
    display: flex;
    color: rgba(var(--custom-nav-color), 1);
    padding: 0.5rem;
    padding-bottom: 0;
    overflow: auto;
}

.custom-nav__item {
    background: rgba(var(--custom-nav__item-background), 1);
    cursor: pointer;
    min-width: max-content;
}

.custom-nav__item:first-child {
    margin-left: auto;
}

.custom-nav__item:last-child {
    margin-right: auto;
}

.custom-nav__item:has(.nav-link.active) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.custom-nav__item>.nav-link {
    background: rgba(var(--custom-nav-background), 1);
    display: inline-block;
    padding: 1rem;
    padding-top: 0.75rem;
    position: relative;
}

.custom-nav__item>.nav-link.active::after {
    background: rgba(var(--custom-nav__link-background), 1);
    content: '';
    height: 4px;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
}

.custom-nav__item:has(+ .custom-nav__item > .nav-link.active)>.nav-link {
    border-bottom-right-radius: 25px;
}

.custom-nav__item:has(.nav-link.active)+.custom-nav__item>.nav-link {
    border-bottom-left-radius: 25px;
}

.custom-nav__item:has(.nav-link.active)>.nav-link {
    background: rgba(var(--custom-nav__link-background), 1);
    color: rgba(var(--custom-nav__item-color), 1);
}

.custom-nav__item>.nav-link.disabled,
.custom-nav__item>.nav-link:hover {
    color: rgba(var(--custom-nav__link-color), 0.5);
}

.custom-nav__item.custom-nav__item--dummy {
    pointer-events: none;
}

.custom-nav__item.custom-nav__item--dummy>a::before {
    content: "-";
    color: #0000;
}
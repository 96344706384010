.app-brand {
    align-items: center;
    color: inherit !important;
    display: flex;
    font-size: 1.33rem;
    gap: 0.75rem;
    margin-right: var(--bs-navbar-brand-margin-end);
    text-decoration: none;
    white-space: nowrap;
}

.app-brand>.pic {
    width: 40px;
    height: 40px;
}